<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import router from '../../../../router/index'

import Stat from "./widget";


/**
 * Home component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
  },
  data() {
    return {
      title: "SUPER ADMIN HOME",
      items: [
        {
          text: "Admin Home"
        },
        {
          text: "Home",
          active: true
        }
      ]
    };
  },

  created() { 
    if(JSON.parse(localStorage.getItem('accessRights')).some(access => access.groupName === 'Teller Dashboard')){
      router.push('/teller-dashboard')
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Stat />
      </div>
    </div>
  </Layout>
</template>